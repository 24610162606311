<template>
  <div id="footer-iframe" class="jumbotron-fluid borad">
    <div class="container list-inline text-center ">
        <p class="list-inline-item">哈尔滨汇嘉智联物业管理有限责任公司</p>
        <p class="list-inline-item">©版权所有</p>
        <p class="list-inline-item"><a href="https://beian.miit.gov.cn/">黑ICP备2020005095号</a></p>
        <!-- 
        <p class="list-inline-item"><a href="https://beian.miit.gov.cn/">黑ICP备2020005095号-1</a></p>
        <p class="list-inline-item"><a href="https://beian.miit.gov.cn/">黑ICP备2020005095号-2</a></p> 
        -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
