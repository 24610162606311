<template>
 <div id="app-page" class="">
    <Header/>
    <router-view/>
    <Footer/> 
    <!-- <Pindex/> -->
 </div>
</template>

<script>
  import Header from '@/components/Header.Iframe.vue';
  import Footer from '@/components/Footer.Iframe.vue';
  // import Pindex from '@/components/Pindex.Iframe.vue';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    // Pindex
    
  },
  methods: {
    
  },
  /*
    computed: {} 适合复杂计算 非复杂计算可以放在 mounted（）{}里面
  */
  computed: {
    _isMobile() {
      //let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      //let flag = navigator.userAgent.match(/Mobile|And/i)
      let flag = navigator.userAgent.match(/Win/i);
      return flag;
    }
  },
  mounted() {

    if(this._isMobile) {
      /*alert("pc端");*/
      this.$router.replace('/');
    }else {
       /*alert("移动端");*/
      this.$router.replace('/About');
    }

  }
}
</script>


<style lang="scss">
  @import './assets/ui/ui.scss';
</style>
