<template>
  <div class="content-iframe">

    <div class="container mb-4" style="margin-top:100px">
      <div class="row">
        <div class="col-xl-2">
        </div>
        <div class="col-xl-8">
          <div class="triangle-bottom d-block mx-auto" style="margin-top:0px"></div>
        </div>
        <div class="col-xl-2">
        </div>
      </div>
    </div>

    <div class="container mb-4" style="margin-top:100px">
      <div class="row">
        <div class="col-xl-2">
        </div>
        <div class="col-xl-8">
          <img src="@/assets/svg/u4.svg" alt="" class="img-fluid d-block mx-auto"  style="margin-top:180px">
          <div class="card-body">
              <p class="idea-text text-center shadow-sm" style="">我们的理念</p>
          </div>
        </div>
        <div class="col-xl-2">
        </div>
      </div>
    </div>

    <div class="container-fluid mb-4" style="margin-top:200px">
      <div class="row"  style="margin-top:180px">
        <div class="col-xl-2">
        </div>
        <div class="col-xl-8">
          <dir class="row" >
          <div class="col-xl-4">
            <img class="img-fluid d-block mx-auto" src="@/assets/svg/u10.svg" alt="" style="width:150px;height:130px;" >
            <p class="idea-text text-center shadow-sm" >数字化</p>
          </div>
          <div class="col-xl-4">
            <img class="img-fluid d-block mx-auto" src="@/assets/svg/u7.svg" alt="" style="width:150px;height:130px;">
            <p class="idea-text text-center shadow-sm">绿色生态</p>
          </div>
          <div class="col-xl-4">
            <img class="img-fluid d-block mx-auto" src="@/assets/svg/u8.svg" alt="" style="width:150px;height:130px;">
            <p class="idea-text text-center shadow-sm">云互联</p>
          </div>
          </dir>
        </div>
        <div class="col-xl-2">
        </div>
      </div>
    </div>

    <div class="container mb-4" style="margin-top:100px">
      <div class="row">
        <div class="col-xl-2">
        </div>
        <div class="col-xl-8">
          <div class="triangle-bottom d-block mx-auto" style="margin-top:20px"></div>
        </div>
        <div class="col-xl-2">
        </div>
      </div>
    </div>

    <div class="container mb-4" style="margin-top:200px">
      <div class="row">
        <div class="col-xl-2">
        </div>
        <div class="col-xl-8">
          <img src="@/assets/svg/u17.svg" alt="" class="img-fluid d-block mx-auto"  style="margin-top:180px">
          <div class="card-body">
              <p class="idea-text text-center shadow-sm" style="">公司运营项目</p>
          </div>
        </div>
        <div class="col-xl-2">
        </div>
      </div>
    </div>

    <div class="container-fluid  mb-4" style="margin-top:200px">
      <div class="row"   style="margin-top:180px">
        <div class="col-xl-2">
        </div>
        <div class="col-xl-8">
          <div class="card shadow">
            <img src="" alt="" class="card-img-top" style="width:100%;height:400px;">
            <div class="card-body">
                <p class="idea-text text-center">DPSM 数字化物业服务管理项目</p>
                <p class="project-text text-center">Digital Property Service Management</p>
            </div>
          </div>
        </div>
        <div class="col-xl-2">
        </div>
      </div>
    </div>

    <div class="container-fluid mb-4" style="margin-top:200px">
      <div class="row">
        <div class="col-xl-2">
        </div>
        <div class="col-xl-8">
          <div class="card shadow">
            <img src="" alt="" class="card-img-top" style="width:100%;height:400px;">
            <div class="card-body">
                <p class="idea-text text-center">CRSM 客户关系与保障管理项目</p>
                <p class="project-text text-center">Customer Relationship Safeguard Management</p>
            </div>
          </div>
        </div>
        <div class="col-xl-2">
        </div>
      </div>
    </div>

    <div class="container-fluid mb-4" style="margin-top:200px">
      <div class="row">
        <div class="col-xl-2">
        </div>
        <div class="col-xl-8">
          <div class="card shadow">
            <img src="" alt="" class="card-img-top" style="width:100%;height:400px;">
            <div class="card-body">
                <p class="idea-text text-center">CCS 社区云服务</p>
                <p class="project-text text-center">Community Cloud Service</p>
            </div>
          </div>
        </div>
        <div class="col-xl-2">
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
