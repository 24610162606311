<template>
  <div id="header-iframe" class="jumbotron-fluid  borad">
    <div class="container">
      <h1 class="text-center font-weight-bold">汇嘉智联</h1>
      <p class="text-center font-weight-bold">汇聚美好.智联天下</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
